import { signInWithRedirect, GoogleAuthProvider } from "firebase/auth";
import { useCallback } from "react";

import { useFirebase } from "../contexts/firebase";

export const useLoginWithGoogle = (): (() => Promise<void>) => {
  const { firebase } = useFirebase();

  const login = useCallback(async () => {
    const provider = new GoogleAuthProvider();
    // enable to select account
    provider.setCustomParameters({
      prompt: "select_account",
    });
    await signInWithRedirect(firebase.auth, provider);
  }, [firebase]);

  return login;
};
