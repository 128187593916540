import { FC } from "react";

import { Center } from "@spwn-portal/ui/chakra";

/**
 * Base https://github.com/balus-co-ltd/spwn/blob/3851a4af04bbabebee0e9a223125afe096f891f2/packages/cms/src/ui/LayoutCenter.tsx#L5
 */
export const LayoutCenter: FC<{
  children: JSX.Element;
}> = ({ children }) => {
  return <Center h={"100vh"}>{children}</Center>;
};
