import { useRouter } from "next/router";
import { FC, useEffect } from "react";

import { Button, Text, Container } from "@spwn-portal/ui/chakra";

import { LoginWithAccountsApp } from "./LoginWithAccountsApp";
import { usePagesQuery } from "./path";
import { envConfig } from "../constants/env";
import { useAuthContext } from "../contexts/auth";
import { useConfig } from "../contexts/config";
import { useLoginWithGoogle } from "../features/useLoginWithGoogle";
import { PageLoader } from "../ui/PageLoader";

/**
 * ログイン
 *
 * ログイン完了までのフロー
 * 1. 例えばプラン一覧ページ https://fanclub.spwn.jp/plans でログインしようとする
 * 2. ログインページへ遷移する
 *   - 例 https://fanclub.spwn.jp/login?redirect_to=https://fanclub.spwn.jp/plans
 *   - このLoginコンポーネントが呼ばれる
 * 3. ログインアプリでログイン完了したら、redirect_toへリダイレクトする
 */
export const Login: FC = () => {
  const auth = useAuthContext();

  const {
    config: { emulators },
  } = useConfig();
  const useEmulator = emulators !== undefined;

  switch (auth.type) {
    case "loading":
      return <PageLoader />;
    case "unauthorized":
      if (envConfig.NODE_ENV === "development" && useEmulator) {
        return <LoginDev />;
      }
      /**
       * ログインアプリ（Portalイベントのログイン）を利用したログイン
       */
      return <LoginWithAccountsApp />;
    case "authorized":
      return <RedirectToReturnPath />;
    default: {
      const _never: never = auth;
      return null;
    }
  }
};

/**
 * ローカル環境用のログインコンポーネント
 * cookieを利用したログインを用意するのが煩雑なため、簡易的なログイン機能のみ提供する
 */
const LoginDev: FC = () => {
  const login = useLoginWithGoogle();
  return (
    <Container py={"4"}>
      <Text>SPWN Portal</Text>
      <Button onClick={login}>Login</Button>
    </Container>
  );
};

/**
 * 指定されたパスへリダイレクトする
 *
 * パスがなければトップへリダイレクトする
 */
const RedirectToReturnPath = () => {
  const router = useRouter();
  const {
    login: { redirect_to },
  } = usePagesQuery();

  /**
   * redirect_to へリダイレクト
   * https://nextjs.org/docs/api-reference/next/router#userouter
   */
  useEffect(() => {
    const validRedirectTo = isValidRedirectTo(redirect_to) ? redirect_to : "/";
    router.push(validRedirectTo);
  }, [redirect_to, router]);

  return <PageLoader />;
};

/**
 * オープンリダイレクトを防ぐために、SPWN FANCLUB内のページへの遷移のみ許可する
 */
const isValidRedirectTo = (path: unknown): path is `/${string}` => {
  try {
    if (typeof path !== "string") {
      return false;
    }
    const pattern = /^\/.+/;
    return pattern.test(path);
  } catch (e) {
    return false;
  }
};
