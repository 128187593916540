import { signInWithCustomToken } from "firebase/auth";
import { useEffect } from "react";

import {
  callVerifySessionCookie,
  getVerifySessionCookieKey,
} from "@spwn-portal/bff/src";

import { envConfig } from "../constants/env";
import { useConfig } from "../contexts/config";
import { useFirebase } from "../contexts/firebase";
import { useSWRImmutable } from "../contexts/swr";
import { PageLoader } from "../ui/PageLoader";

/**
 * accountsアプリ（Portalイベントのログイン）を利用したログイン
 *
 * - ログイン完了するまでを責務とする
 *   - ログイン後の挙動は Login コンポーネントに任せる
 */
export const LoginWithAccountsApp = () => {
  const {
    config: {
      app: { accountsAppUrl: origin },
    },
  } = useConfig();
  const { data, error } = useGetCustomToken();

  useRedirectToAccountsApp(origin, error);
  useLoginWithCustomToken(data?.customToken);

  return <PageLoader />;
};

/**
 * ログインしていなかったら（エラーが出たら）ログインアプリへリダイレクト
 * @param error
 */
const useRedirectToAccountsApp = (origin: string, error: unknown) => {
  useEffect(() => {
    if (!error) return;
    const accountsAppUrl = encodeReturnUrl({
      accountsAppUrl: origin,
      returnUrl: window.location.href,
    });
    window.location.replace(accountsAppUrl);
  }, [origin, error]);
};

const encodeReturnUrl = ({
  accountsAppUrl,
  returnUrl,
}: {
  accountsAppUrl: string;
  returnUrl: string;
}) => {
  return `${accountsAppUrl}/login?return_url=${encodeURIComponent(returnUrl)}`;
};

/**
 * カスタムトークンを利用してログインする
 * @param customToken
 */
const useLoginWithCustomToken = (customToken: string | undefined) => {
  const { firebase } = useFirebase();
  useEffect(() => {
    if (customToken === undefined) return;
    (async () => {
      /**
       * 返り値をセットしなくても、onAuthStateChanged によってログイン検知される
       */
      const _userCredential = await signInWithCustomToken(
        firebase.auth,
        customToken
      );
    })();
  }, [customToken, firebase.auth]);
};

/**
 * accountsアプリケーションから取得し、cookieに設定したセッションCookieからカスタムトークンを取得する
 * @returns
 */
const useGetCustomToken = () => {
  const { data, error } = useSWRImmutable(
    getVerifySessionCookieKey(),
    async () => {
      const response = await callVerifySessionCookie(
        envConfig.NEXT_PUBLIC_BACKEND_API_URL,
        {}
      );
      if (response.ok) {
        return response.data;
      }
      /**
       * いずれのエラーもログインが必要なためとして、エラーごとの条件分岐は行わない
       *
       * 考えられるエラー
       * - cookieにセッションcookieがセットされていない
       * - セッションcookieが期限切れ
       */
      throw new Error("ログインが必要なためリダイレクトを行います");
    },
    {
      /**
       * 現状 suspense はデフォルトで有効にしている
       * しかし有効にすると、useSWRからエラーが返ってこなくなり、
       * エラーによるリダイレクトやログインのハンドリングがやりづらくなるため無効にしている
       * https://swr.vercel.app/docs/suspense
       */
      suspense: false,
    }
  );

  return {
    data,
    error,
  };
};
