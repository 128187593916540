import { GetStaticProps, NextPage } from "next";

import { getStaticPropsWithTranslations } from "@spwn-portal/i18n-utils/server";

import { FAN_CLUB_COMMON_DESCRIPTION } from "../constants/crew";
import { HeadSeo } from "../features/HeadSeo";
import { Login } from "../features/Login";

export type OptionalQuery = {
  redirect_to: string;
};

const LoginPage: NextPage = () => {
  return (
    <>
      <HeadSeo
        path={"/login"}
        title={{
          type: "default",
          text: "ログイン",
        }}
        description={FAN_CLUB_COMMON_DESCRIPTION}
        noindex
      />
      <Login />;
    </>
  );
};

export default LoginPage;

export const getStaticProps: GetStaticProps = getStaticPropsWithTranslations;
