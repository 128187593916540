import NextHeadSeo from "next-head-seo";

import { FAN_CLUB_COMMON_DESCRIPTION, FAN_CLUB_TITLE } from "../constants/crew";
import { envConfig } from "../constants/env";

export type HeadSeoProps = {
  path: string;
  /**
   * ページのタイトル
   *
   * - noTemplate: {text}
   * - default: {text} | CREW
   * - brandPage: {text} | {brandName} | CREW
   */
  title?:
    | {
        type: "noTemplate";
        text: string;
      }
    | {
        type: "default";
        text: string;
      }
    | {
        type: "brandPage";
        brandName: string;
        text: string;
      };
  description?: string;
  /**
   * og imageの絶対パスURL
   */
  ogImageUrl?: string;
  noindex?: boolean;
};

/**
 * @see https://github.com/catnose99/next-head-seo#create-wrapper-component-for-next-head-seo
 */
export const HeadSeo: React.FC<HeadSeoProps> = (props) => {
  /**
   * 複数の異なるドメインが同じアプリケーションのURLを利用するために、vercel envで設定したものを利用している
   * prod: https://crew.spwn.jp
   */
  const APP_ROOT_URL = envConfig.NEXT_PUBLIC_APP_ROOT_URL;

  const {
    path,
    title,
    description = FAN_CLUB_COMMON_DESCRIPTION,
    // FIXME: デフォルトは二行ロゴにしてみてる
    ogImageUrl = APP_ROOT_URL + "/images/spwn-crew-two-line-logo.png",
    noindex,
  } = props;

  const robots =
    envConfig.NEXT_PUBLIC_VERCEL_ENV === "production"
      ? noindex
        ? "noindex, nofollow"
        : undefined
      : // 本番環境以外ではnoindexにしておく
        "noindex, nofollow";

  // ページURL（絶対パス）
  const pageUrl = APP_ROOT_URL + path;
  const pageTitle = generateTitle(title);

  return (
    <NextHeadSeo
      title={pageTitle}
      /**
       * 重複コンテンツがある、同じ内容のURLが複数存在する場合に、評価してほしいURLがどれかを検索エンジンに示すために用いる
       * 絶対パス指定
       */
      canonical={pageUrl}
      description={description}
      robots={robots}
      og={{
        title: pageTitle,
        description,
        url: pageUrl,
        image: ogImageUrl,
        type: "article",
        siteName: FAN_CLUB_TITLE,
      }}
      twitter={{
        card: "summary_large_image",
      }}
      customLinkTags={[
        {
          rel: "icon",
          href: "/images/favicon-64x64.png",
        },
        {
          rel: "apple-touch-icon",
          href: "/images/favicon-128x128.png",
        },
      ]}
    />
  );
};

const generateTitle = (title: HeadSeoProps["title"]): string => {
  switch (title?.type) {
    case undefined:
      return FAN_CLUB_TITLE;
    case "noTemplate":
      return title.text;
    case "default":
      return `${title.text} | ${FAN_CLUB_TITLE}`;
    case "brandPage":
      return `${title.text} | ${title.brandName} | ${FAN_CLUB_TITLE}`;
    default: {
      const _never: never = title;
      throw new Error();
    }
  }
};
