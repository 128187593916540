import { Box, Heading, Spinner, VStack } from "@spwn-portal/ui/chakra";

import { LayoutCenter } from "../ui/LayoutCenter";

/**
 * ページローダー
 *
 * Base https://github.com/balus-co-ltd/spwn/blob/3851a4af04bbabebee0e9a223125afe096f891f2/packages/cms/src/ui/ContentsLoader.tsx#L7
 */
export const PageLoader = (
  {
    message,
  }: {
    message?: string;
  } = {
    message: "読込中",
  }
): JSX.Element => {
  return (
    <LayoutCenter>
      <Box as="section">
        <VStack spacing={6}>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="lg"
            aria-label={"読込中"}
            aria-busy={true}
          />
          <Heading fontSize={"lg"}>{message}</Heading>
        </VStack>
      </Box>
    </LayoutCenter>
  );
};
